.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body {
  font-family: Verdana;
}

.prec {
  top: 24px;
  position: relative;
  font-size: 25px;
}

.circle {
  position: relative;
  top: 5px;
  left: 5px;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #E6F4F7;
}

label {
  font-weight: bold;
  font-size: 0.7em;
  margin-top: 10px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.active-border {
  position: relative;
  text-align: center;
  width: 90px;
  height: 90px;
  border-radius: 100%;

  background-color: #39B4CC;
  background-image: linear-gradient(91deg, transparent 50%, #A2ECFB 50%),
  linear-gradient(90deg, #A2ECFB 50%, transparent 50%);
  margin: auto;


}

.simple-info {
  text-align: center;
  font-size: 0.85em;
  margin-bottom: 15px;
}

.last-sync-date {
  text-align: center;
  font-size: 0.6em;
  margin-top: 25px;
}

#latestTrip {
  font-size: 0.8em;
}

#score {
  font-size: 0.8em;


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    margin-top: -5px;

    li {
      margin-top: 5px;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

#installBtn {
  height: 40px;
  width: 100px;
  position: absolute;
  bottom: 20px;
  left: 3px;
  font-weight: bold;
  font-size: 14px;

  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #113c72;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 3px #111;

  display: none;
}
